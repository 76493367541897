export const images = {
        firstRow: [
            {
                id: 1,
                title: `Ali bo božič bel?`,
                content: `Ali bo božič bel, še prej pa, kdaj bo snežilo? To sta vprašanji, ki si jih najpogosteje zastavljamo pred prihajajočimi prazniki. Marsikdo si želi belega decembra, saj že skromna snežna odeja popolnoma spremeni pokrajino in doda čarobnost prazničnemu času. A eno so želje, drugo pa je realnost.& 
                Pravo zimsko idilo, ko je snežna odeja tla prekrivala več kot polovico decembra, smo na Ribniškem v zadnjih dvajsetih letih imeli le petkrat, torej v povprečju vsako četrto leto, medtem ko je bila v 70., 80. in 90. letih prejšnjega stoletja takih več kot polovica decembrov.& 
                Najbolj zimski december smo v zadnjem obdobju imeli leta 2010, ko na Ribniškem le 24. in 25. decembra ni bilo snega, največ, kar 60 centimetrov, pa ga je bilo tistega leta petega decembra. Miklavž se je lahko pripeljal s sanmi tudi lani, ko je tla prekrivala veliko bolj skromna 15-centimetrska snežna odeja.&
                Kar deset let pa je že minilo od zadnjega belega božiča, torej lahko rečemo, da so zeleni božični prazniki postali kar naša stalnica. Tudi sicer smo se v zadnjih dvajsetih letih v belo božično jutro na Ribniškem zbudili le štirikrat. Najbolj bel je bil božič 2001, ko je tla prekrivala 15-centimetrska snežna odeja.&
                V arhivu Agencije RS za okolje lahko najdemo še več zanimivih dogodkov, ki so zaznamovali praznični čas v preteklosti. Od sredine prejšnjega stoletja je bil v večjem delu nižinskega sveta s snegom najbolj radodaren božič 1994. Sneg, ki je takrat prekrival večji del države, je zapadel med 20. in 24. decembrom. Na Ribniškem in v Loškem Potoku ga je bilo okoli pol metra. A že kmalu po božiču nas je dosegla občutna otoplitev. Ob kombinaciji južnega vetra in dežja se je snežna odeja hitro tanjšala in do novega leta se je stalil ves sneg.&
                Leta 2009 sta nas sneženje in mraz zajela že pred prazniki, nato pa je sledila izrazita odjuga in marsikje smo imeli najtoplejši božič v zgodovini meritev. Na Ribniškem se je ogrelo do 15 stopinj Celzija. Močni južni vetrovi so nam poleg visokih temperatur prinesli tudi obilne padavine. Ponekod toliko dežja v decembru ni padlo še nikoli do zdaj. Meja sneženja se je močno dvignila in sneg se je mešal z dežjem celo na naši najvišji meteorološki postaji na Kredarici.&
                Zanesljive napovedi, kakšni bodo letošnji prazniki, še ni, je pa možnost, da nas bo pričakalo belo božično jutro, zaradi podnebnih sprememb za vsaj polovico manjša, kot je bila pred tridesetimi in več leti.&
                `,
                url: "https://postaje.vreme-podnebje.si/images/wiki/image1.jpg"
            },
            {
                id: 2,
                title: `So ledeni možje mit ali resnica?`,
                content: `Od 12. do 14. maja godujejo ledeni možje, ki jim dan kasneje sledi še poscana Zofka. Dolga stoletja so bili ti svetniki glavni “krivci” za mrzlo in deževno vreme, ki se lahko še pojavi v maju. Pankracij, Servacij in Bonifacij so pred uvedbo gregorijanskega koledarja godovali že med 2. in 4. majem. Kljub kasnejši spremembi koledarja so se ledeni možje ohranili, s tem pa tudi pregovori, ki pravijo, da se po godovih teh svetnikov ni treba več bati slane.&
                V zadnjih šestdesetih letih so nam Pankracij, Servacij in Bonifacij za ta čas nezaželene pojave (mraz in sneg) prinesli le nekajkrat, izraziti in prisotni po vsej Sloveniji pa so bili le leta 1978. Takrat se je temperatura že 11. maja marsikje močno približala ledišču, še bolj mrzlo pa je bilo naslednje jutro, ko se je živo srebro v najbolj mrzlih krajih spustilo tudi do ¬¬5 stopinj pod ničlo. Le dan kasneje (13. maja 1978) pa je nekatere kraje pobelil sneg. V Loškem Potoku in Sodražici ga je zapadlo 12, v Ribnici pa 6 centimetrov.& 
                Precej pogosteje kot s hladom so nas ledeni možje razvajali s soncem in visokimi temperaturami. V vremenskem arhivu tako najdemo dogodek, ko smo ravno v času ledenih mož imeli zgodnji vročinski val. To se je zgodilo leta 1969, ko se je na Bizeljskem 14. maja ogrelo kar do 33 stopinj Celzija. Zakaj potem Pankraciju, Servaciju in Bonifaciju sploh pravimo ledeni možje?&
                Gre verjetno za spoznanje, da ravno sredina maja predstavlja nekakšno ločnico, ko lahko še pride do spustov hladnega zraka z možnostjo snega in slane po nižinah. Seveda to ne velja za višje ležeča mrazišča, kjer se lahko temperatura pod ničlo spusti vse leto, tudi sredi poletja.&
                `,
                url: "https://postaje.vreme-podnebje.si/images/wiki/image2.jpg"
            },
            {
                id: 3,
                title: `Zakaj se Slovenija segreva dvakrat hitreje od povprečja?`,
                content: `Podatki kažejo, da se je povprečna globalna temperatura zraka od konca 19. stoletja do leta 2016 dvignila za eno stopinjo Celzija. Podatki za Slovenijo medtem kažejo na še precej višji porast temperature, ki presega dve stopinji Celzija. V primerjavi z dolgoletnim povprečjem so se pri nas najbolj segrela poletja, sledijo pomladi in zime, medtem ko jeseni ni zaznati večjih sprememb.&
                Eden od razlogov, zakaj se Slovenija segreva dvakrat hitreje od svetovnega povprečja, je razporeditev kopnega in morja. Nad oceani se namreč ozračje segreva precej počasneje kot nad celino. Ali z drugimi besedami povedano, manjši kot je vpliv oceana, hitrejše je segrevanje ozračja. Podnebne spremembe sicer niso nič novega, saj so se dogajale v vsej zgodovini našega planeta, so pa v zadnjih 150 letih zaradi človekove dejavnosti bistveno hitrejše, kot so bile nekoč.&
                `,
                url: "https://postaje.vreme-podnebje.si/images/wiki/image3.jpg"
            }
        ],
        secondRow: [
            {
                id: 4,
                title: `Pozitivne in negativne povratne zanke`,
                content: `Podnebje se na nekaterih delih Zemlje spreminja bistveno hitreje kot drugje, saj na teh območjih prihaja do t. i. pozitivnih povratnih zank. Kot primer take zanke omenimo permafrost oziroma trajno zamrznjena tla na območju Kanade in Sibirije, kjer je shranjena večja količina toplogrednih plinov, predvsem metana. Ta tla so se zaradi toplejšega vremena začela taliti, toplogredni plini pa so se začeli sproščati v ozračje, kar še dodatno pospešuje dvig temperatur.&
                Primer pozitivne povratne zanke je tudi arktični morski led, ki se zaradi vse višjih temperatur prav tako tali, taljenje tega pa pospešuje še dejstvo, da nezaledenela morska površina vpije bistveno več Sončeve energije, kar le še bolj segreva to območje. Prav v polarnih predelih se je ozračje v zadnjih 150 letih najbolj ogrelo, in sicer za okoli 3,5 stopinje Celzija.&
                Zaradi taljenja arktičnega ledu se proti jugu zlivajo ogromne količine mrzle vode, ki hladijo severni Atlantik in posledično tudi zrak nad njim. To pa je primer negativne povratne zanke, zaradi česar se je ozračje nad severnim Atlantikom v zadnjih 150 letih celo nekoliko ohladilo. Pozitivna povratna zanka torej pospešuje podnebne spremembe, negativna pa jih zavira.&
                `,
                url: "https://postaje.vreme-podnebje.si/images/wiki/image4.jpg"
            },
            {
                id: 5,
                title: `Plohe in nevihte – napoved, ki (ne) drži?`,
                content: `Za plohe in nevihte je značilna velika časovna in prostorska spremenljivost, zaradi česar so težko napovedljive. Običajno trajajo le kratek čas in se pojavljajo na manjših območjih. V vremenskih napovedih prognostiki zato podajajo le verjetnost, da bo do nevihte prišlo. Na podlagi višinskih vetrov in padca temperature z višino lahko predvidijo smer premikanja in intenziteto nastalih neviht, prav tako lahko ocenijo, ali se bodo nevihte pojavljale nad celotno državo, ali bodo omejene le na posamezna območja, ne morejo pa napovedati točnega kraja in časa njenega nastanka. Včasih se zgodi, da proces, ki povzroči nastanek neviht, nekoliko zamudi ali pa pride prej, s tem pa je tudi vremensko dogajanje manj ali bolj burno od prvotno predvidenega.&
                Omemba ploh in neviht ne pomeni, da bo deževalo noč in dan, prav tako to tudi ne pomeni, da bo deževalo povsod. Na določeni lokaciji običajno pada največ dve uri, potem pa se dogajanje preseli drugam. Plohe in nevihte so namreč kratkotrajni pojavi, ki običajno zajamejo le manjša območja.& 
                Glavni pogoj za nastanek neviht je nestabilno ozračje, ki je posledica velike temperaturne razlike med nižjimi in višjimi plastmi ozračja. To se še posebej pogosto dogaja ob koncu pomladi in na začetku poletja, ko je sonce že visoko in močno segreva spodnje plasti ozračja, medtem ko se v višinah še zadržuje razmeroma hladen zrak. Takrat se topel zrak, ki je lažji od okolice, začne dvigati v hladnejše višine. V primeru, da je zrak dovolj vlažen, na neki višini pride do kondenzacije in nastanka oblaka, iz katerega se izločijo padavine, kar se običajno najprej zgodi nad hribi, nato pa lahko višinski vetrovi dež zanesejo tudi nad ravnine.&
                Zaradi bližine Dinarske pregrade, katere del sta tudi Velika in Mala gora, so plohe in nevihte na Ribniškem in v Loškem Potoku kar pogoste. Nevihtna aktivnost se običajno močno okrepi v maju, doseže svoj vrh v juniju, nato pa počasi usahne.&
                `,
                url: "https://postaje.vreme-podnebje.si/images/wiki/image5.jpg"
            },
        ],
        thirdRow: [
            {
                id: 6,
                title: `Kako zanesljive so srednjeročne napovedi vremena?`,
                content: `Ko je vreme stabilno, kar pomeni, da se je nad nami usidralo območje visokega zračnega tlaka s šibkimi višinskimi vetrovi, je vremenska napoved zelo enostavna. Poleti lahko pričakujemo sončno vreme z morda le kakšno popoldansko nevihto nad goratimi predeli, pozimi pa po nižinah običajno veliko megle ali nizke oblačnosti, iz katere lahko ponekod tudi rahlo rosi ali naletava sneg. Takšna situacija z anticiklonom lahko traja tudi dalj časa, zato lahko zanesljivo napoved izdamo za deset ali celo štirinajst dni naprej.&
                Precej bolj težavna je napoved, ko nad nami pihajo močnejši vetrovi, ki proti našim krajem prinašajo vremenske fronte ali ko se nad nami zadržujejo višinska jezera hladnega zraka. Takrat je natančna časovnica vremenskega dogajanja pogosto težko določljiva že za nekaj dni naprej, saj lahko že minimalne spremembe v gibanju zračnih mas povsem spremenijo vremensko napoved. Posebej težko je napovedati poletne plohe in nevihte, za katere je značilna velika časovna in prostorska razpršenost. Vemo, da bodo nevihte nastajale, lahko opredelimo tudi približen čas in območje njihovega nastanka, medtem ko natančnega kraja in ure žal ni mogoče napovedati.&
                `,
                url: "https://postaje.vreme-podnebje.si/images/wiki/image6.jpg"
            },
            {
                id: 7,
                title: `Kako nastane vremenska napoved?`,
                content: `Za izračun vremenske napovedi se uporabljajo različni meteorološki modeli, ki temeljijo na fizikalnih zakonitostih v ozračju. Enačbe v meteoroloških modelih so zelo zapletene, zato jih lahko v doglednem času rešijo le najzmogljivejši računalniki na svetu, tako imenovani superračunalniki. V osnovi gre za to, da za del zraka izračunamo, kam bo šel, koliko časa bo potoval, kaj se bo z njim dogajalo, kakšno vlažnost bo imel - od tega je potem odvisna količina oblačnosti in temperatura.& 
                Gre za izjemno zapleten sistem, v katerem lahko že ena zelo majhna sprememba povsem spremeni nadaljnji potek vremena, zato glavni meteorološki centri na svetu namesto ene izdelajo več različnih napovedi. Evropski ECMWF na vsakih dvanajst ur izda 50 izračunov za 10 dni naprej in sicer tako, da vsakemu izračunu nekoliko spremeni začetno stanje. Tako dobimo nabor možnih razvojev vremena ali t. i. ansambelsko napoved. Vremenski prognostik nato izbere najverjetnejši scenarij, torej tistega, ki ga prikazuje največ članov ansambelske napovedi, z razpršenostjo le-teh pa lahko tudi oceni, kako zanesljiva je ta napoved in, ali obstaja možnost še kakšnega drugačnega razvoja vremena.& 
                `,
                url: "https://postaje.vreme-podnebje.si/images/wiki/image7.jpg"
            },
            {
                id: 8,
                title: `Je april vremensko res najbolj muhast mesec?`,
                content: `Prva misel v mesecu aprilu sta dež in dežnik, vendar april v nasprotju z ljudskim izročilom in s splošnim prepričanjem spada med manj namočene mesece. Na Ribniškem in v Loškem Potoku več padavin kot aprila izmerimo v poletnih in jesenskih mesecih, ko so tudi temperature višje in lahko zrak sprejme več vodne pare, posledično so tudi vremenski procesi intenzivnejši. Kljub razmeroma majhni količini padavin pa so le te aprila razmeroma pogoste, saj le maja in junija dežuje bolj pogosto, najmanj padavinskih dni pa imamo februarja.&
                Vtis spremenljivega vremena dajejo za april značilne plohe in nevihte, ki se običajno začnejo pojavljati ravno v tem mesecu. Čeprav moč sončnih žarkov v začetku aprila hitro narašča in je pri tleh vse topleje, se zrak v višinah po mrzli zimi ogreva z zakasnitvijo. Ob veliki temperaturni razliki med nižjimi in višjimi legami postane ozračje nestabilno. Takrat se toplejši zrak pri tleh začne dvigati v hladnejše višine in ko doseže višino kondenzacije, nastane oblak, iz katerega se izločijo padavine, t. i. plohe, ki so pogosto zelo krajevnega značaja.&
                Ob plohah se ozračje običajno močno ohladi. Zapiha hladen veter, ki lahko mejo sneženja za krajši čas spusti vse do nižin. Tako aprila ni nič nenavadnega, če imamo v enem samem dnevu tudi več vrst padavin, od dežja, sodre, toče, babjega pšena do snega.&
                `,
                url: "https://postaje.vreme-podnebje.si/images/wiki/image8.jpg"
            }
        ],
        fourthRow: [
            {
                id: 9,
                title: `Babje poletje`,
                content: `Za visoke temperature, ki sledijo prvemu jesenskemu hladu, se je uveljavil izraz babje ali tudi indijansko poletje. Teorije o njegovem izvoru se precej razlikujejo. Ena od razlag obdobje sončnega in toplega vremena, ki sledi prvi izrazitejši jesenski ohladitvi in ga močno zaznamuje pisana obarvanost narave, povezuje s pisanimi oblačili in poslikavami teles ameriških staroselcev. Prav tako se to obdobje neredko razlaga tudi kot čas ženskih opravil, kot sta spravilo žita, predenje lanu in podobno. V Evropo je izraz indijansko poletje prišel z druge strani Atlantika, pred tem pa je bil pri nas v uporabi izraz babje poletje. V Angliji in nekaterih sredozemskih državah se uporablja tudi izraz Martinovo poletje, saj toplo vreme pogosto sovpada s čaščenjem svetega Martina, ki goduje 11. novembra.&
                Najtoplejše Martinovo smo v zadnjih 20 letih imeli leta 2015, ko smo na Ribniškem beležili 21, v Loškem Potoku pa 19 stopinj Celzija. Tudi sicer je prvih 20 dni novembra pred šestimi leti zaznamovalo sončno in ob popoldnevih zelo toplo vreme. V Prigorici se je 9. novembra ogrelo kar do 23,4 stopinje Celzija.&
                Povsem drugačno Martinovo smo imeli leta 2016, ko se je ob izraziti hladni fronti meja sneženja spustila do nižin. Po najnižjih delih Ribniške doline je bilo snega sicer le za vzorec, a že kakšnih sto metrov višje so bile razmere povsem zimske. Hrib – Loški Potok je po podatkih Arsa naslednje jutro prekrivala 23-centimetrska snežna odeja.&
                `,
                url: "https://postaje.vreme-podnebje.si/images/wiki/image9.jpg"
            }
        ]
    }

export default images;