<template>
  <div role="button" class="overlay">
    <div class="position-absolute text-white text-wrap p-2 text-uppercase fs-6 fw-bolder mobile" style="width: 20rem;">
      {{title}}
    </div>
    <img class="img img-fluid" :src="src" />
  </div>
</template>

<script>
export default {
  name: "Wiki",
  props: {
    src: String,
    title: String
  },
};
</script>

<style scoped>
[role="button"] {
  cursor: pointer;
}

/* Bottom left text */
.bottom-left {
  position: absolute;
}

.overlay {
  opacity: 1;
  transition: 0.5s ease;
}

.col-12:hover .overlay {
  opacity: 0.7;
}

.mobile {
  bottom: 0;
  left: 25;
}

@media (max-width: 1000px) {
  .mobile {
    bottom: auto;
    left: auto;
  }
}
</style>