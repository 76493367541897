<template>
  <h2 class="text-center mt-4 title fw-bolder">Vremenkopedija</h2>
  <div class="container-fluid mt-4">
    <div class="row g-2 justify-content-center">
      <div class="col-12 col-xl-3 position-relative" v-for="item in WikiData.firstRow" :key="item" @click="GetDetails(item)">
        <Wiki :src="item.url" :title="item.title" />
      </div>
    </div>
    <div class="row g-2 justify-content-center mt-1 position-relative">
      <div class="col-12 col-xl-4" v-for="item in WikiData.secondRow" :key="item" @click="GetDetails(item)">
        <Wiki :src="item.url" :title="item.title" />
      </div>
    </div>
    <div class="row g-2 justify-content-center mt-1 position-relative">
      <div class="col-12 col-xl-3" v-for="item in WikiData.thirdRow" :key="item" @click="GetDetails(item)">
        <Wiki :src="item.url" :title="item.title" />
      </div>
    </div>
     <div class="row g-2 justify-content-center mt-1 position-relative">
      <div class="col-12 col-xl-4" v-for="item in WikiData.fourthRow" :key="item" @click="GetDetails(item)">
        <Wiki :src="item.url" :title="item.title" />
      </div>
    </div>
  </div>
</template>

<script>
import Wiki from "../components/Wiki/Wiki.vue";
import WikiData from "../Utils/Wiki/Wiki";

export default {
  name: "WeatherWiki",
  data() {
      return {
          WikiData: WikiData,
          title: "",
          content: ""
      };
  },
  methods: {
    GetDetails(wikiItem) {
      this.$router.push({name: 'EncyclopediaDetails', params: { id: wikiItem.id }});
    },
  },
  components: {
    Wiki,
  },
};
</script>

<style scoped>
.title {
    font-size: 37px;
}
</style>